/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
	box-sizing: border-box;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

* {
  transition: 0.25s all;
}
html {
  scroll-behavior: smooth;
}
:root {
  --primary: #B51989;
  --primary-light: rgba(181,25,137,0.1);
  --active: rgba(100,226,0,0.2);
  --inactive: rgba(226,54,0,0.2);
  --white50: rgba(255,255,255,0.5);
  --white80: rgba(255,255,255,0.8);
  --black10: rgba(0,0,0,0.1);
  --black25: rgba(0,0,0,0.25);
  --certified: #B7EEFF;
  --lightest-gray: #F7F7F7;
  --light-gray: #CCC;
  --gray: #666;
  --darker-gray: #333;
  --base-unit: 4px;
}
::selection {
  background-color: var(--primary-light);
}
@font-face {
  font-family: 'Inter';
  src: url('./assets/Inter-VariableFont_slnt,wght.ttf') format('truetype');
  font-display: swap;
}
.loading {
  position: fixed;
  background-color: var(--white50);
  backdrop-filter: blur(var(--base-unit));
  -webkit-backdrop-filter: blur(var(--base-unit));
  text-align: center;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 1000;
}
.loading h1 {
  color: var(--primary);
}
.notification {
  position: fixed;
  top: calc(var(--base-unit)*10);
  left: calc(var(--base-unit)*10);
  background-color: var(--white50);
  border-radius: var(--base-unit);
  padding: calc(var(--base-unit)*3) calc(var(--base-unit)*5);
  border:1px solid var(--black10);
  font-size: 90%;
  font-variation-settings: 'wght' 600;
  color: var(--gray);
  backdrop-filter: blur(var(--base-unit));
  -webkit-backdrop-filter: blur(var(--base-unit));
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
}
.notification.active, div.active {
  opacity: 1;
  visibility: visible;
}
.cobre-gradiente {
  opacity: 0.5;
  background: var(--gradient-over-image, linear-gradient(180deg, rgba(181, 25, 137, 0.00) 0%, #B51989 79.69%));
  mix-blend-mode: multiply;
}
.modal {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}
.modal iframe {
  margin-bottom: calc(var(--base-unit)*4);
}
.modal  button {
  position: fixed;
  top: calc(var(--base-unit)*5);
  right: calc(var(--base-unit)*5);
  z-index: 999;
}

.modal .content {
  background-color: white;
  padding: calc(var(--base-unit)*4);
  border-radius: var(--base-unit);
  z-index: 999;
  top: 50%;
  left: 50%;
  position: absolute;
  transform:translate(-50%,-50%);
  width: 75vw;
  box-shadow: 0 0 calc(var(--base-unit)*5) var(--black25);
}
.modal .background {
  height: 100vh;
  width: 100vw;
  position: relative;
  top: 0;
  left: 0;
  background-color: var(--white50);
  backdrop-filter: blur(calc(var(--base-unit)*2));
  -webkit-backdrop-filter: blur(calc(var(--base-unit)*2));
  z-index: 997;
}
abbr:hover {
  cursor: help;
}
body {
  font-family: 'Inter',sans-serif;
  background-color: var(--lightest-gray);
  color: var(--gray);
}
body.paddingBottom, body.paddingBottom footer {
  padding-bottom: calc(var(--base-unit)*10);
}
body.paddingBottom footer > div:last-child {
  border-radius: var(--base-unit) !important;
}
strong {
  color: var(--darker-gray);
  font-variation-settings: 'wght' 600;
}
a {
  color: var(--primary);
  text-decoration: underline;
}
:hover {
  transition: 0.25s all;
}
main a:hover, footer .columns-footer a:hover {
  opacity: 0.75;
  text-decoration: none;
}
.container {
  max-width: 1200px;
  margin: 0 auto;
}
header {
  backdrop-filter: blur(calc(var(--base-unit)*2));
  -webkit-backdrop-filter: blur(calc(var(--base-unit)*2));
  background-color: var(--white80);
  border-bottom: 1px solid var(--black10);
  margin-bottom: calc(var(--base-unit)*8);
}
header a {
  text-decoration: none;
  color: var(--gray);
}
header .top {
  padding: calc(var(--base-unit)*4) 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
header .top button {
  display: none;
}
header .brand_and_search {
  display: flex;
}
header .brand_and_search a {
  color: var(--primary);
  font-variation-settings: 'wght' 600;
  margin-right: calc(var(--base-unit)*3);
  font-size: 120%;
  display: inline-flex;
  align-items: center;
  column-gap: calc(var(--base-unit)*2);
}
header .brand_and_search a .card {
  color: var(--primary);
  fill: var(--primary);
}
header .brand_and_search a .cnpj, header .brand_and_search a .lines {
  color: white;
  fill: white;
}
header .brand_and_search a:hover svg {
  scale: 1.25;
  transform: rotate(-2.5deg);
}
header .brand_and_search a:hover svg .card {
  color: var(--primary-light);
  fill: var(--primary-light);
}
header .brand_and_search a:hover .cnpj, header .brand_and_search a:hover .lines {
  color: var(--primary);
  fill: var(--primary);
}
header .brand_and_search form {
  display: inline-block;
}
header ul {
  display: flex;
  column-gap: calc(var(--base-unit)*8);
}
header .global a {
  padding: calc(var(--base-unit)*4) 0;
  display: block;
}
header .global a, header .secondary a {
  font-size: 90%;
}
header .secondary a:hover {
  color: black;
  text-decoration: underline;
}
header .bottom {
  display: flex;
  justify-content: space-between;
}
header .global a:hover {
  color: black;
}
header .global li {
  border-bottom: var(--base-unit) solid transparent;
}
header .global li:hover {
  border-bottom: var(--base-unit) solid var(--primary-light);
}
header .global li.active {
  border-bottom: var(--base-unit) solid var(--primary);
}
header .global li.active a {
  color: var(--primary);
  font-variation-settings: 'wght' 600;
}
header .bottom button {
  background-color: transparent;
  border: 0;
  color: var(--gray);
  font-family: 'Inter',sans-serif;
  font-size: 75%;
  cursor: pointer;
  padding: calc(var(--base-unit)*4) 0;
}
header .bottom button:hover {
  color: black;
  text-decoration: underline;
}
header li > ul {
  display: none;
}
header li:has(ul) ul {
  position: absolute;
  background-color: white;
  bottom: 0;
  transform: translateY(100%);
}
header li:has(ul) ul li a {
  padding: calc(var(--base-unit)*4) !important;
  white-space: nowrap;
}
header li:has(ul) {
  position: relative;
}
header li:has(ul):hover ul {
  z-index: 999;
  display: block;
  box-shadow: 0 var(--base-unit) calc(var(--base-unit)*2) var(--black10);
}
header li:has(ul):hover ul li {
  border: 0;
}
header li:has(ul):hover ul li:hover {
  border: 0;
  background-color: var(--primary-light);
}
header li:has(ul):hover ul li:hover a {
  color: black;
}
header input {
  min-width: calc(var(--base-unit)*100);
}
input[type="search"], input[type="text"] {
  border: 2px solid var(--black10);
  padding: calc(var(--base-unit)*3);
  border-radius: var(--base-unit);
  color: black;
  font-family: 'Inter',sans-serif;
  font-variation-settings: 'wght' 700;
}
input[type="search"]:focus, input[type="text"]:focus {
  outline: 0;
  border-color: var(--gray);
  background-color: #f7f7f7;
}
input[type="search"] {
  background-image: url('data:image/svg+xml;utf8,<svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.86951 10.3696L12.9999 13.5" stroke="black" stroke-width="1.5" strokeLinecap="round" stroke-linejoin="round"/><path d="M5.95652 11.413C8.69393 11.413 10.913 9.19393 10.913 6.45652C10.913 3.71911 8.69393 1.5 5.95652 1.5C3.21911 1.5 1 3.71911 1 6.45652C1 9.19393 3.21911 11.413 5.95652 11.413Z" stroke="black" stroke-width="1.5" strokeLinecap="round" stroke-linejoin="round"/></svg>');
  background-position: right calc(var(--base-unit)*3) center;
  background-repeat: no-repeat;
  padding-right: calc(var(--base-unit)*10);
}
input:focus.invalid {
  border-color: red !important;
  background-color: var(--inactive);
}
input:focus.valid {
  border-color: green !important;
  background-color: var(--active);
}
input::placeholder {
  color: var(--light-gray);
  font-variation-settings: 'wght' 300;
}
.columns-1fr_300px {
  display: grid;
  grid-template-columns: 1fr 300px;
  grid-gap: calc(var(--base-unit)*8);
}
.columns-330px_1fr {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: calc(var(--base-unit)*8);
}
.columns-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: calc(var(--base-unit)*8);
}
.columns-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: calc(var(--base-unit)*8);
}
.columns-footer {
  display: grid;
  /* grid-template-columns: 1fr 1fr 1fr 2fr; */
  grid-template-columns: 1fr 3fr;
  grid-gap: calc(var(--base-unit)*8);
  font-size: 90%;
  line-height: 1.5;
}
aside > div {
  position: sticky;
  top: calc(var(--base-unit)*8);
  margin-top: 0;
}
aside form button {
  width: 100%;
  margin-top: calc(var(--base-unit)*2);
}
form {
  position: relative;
}
form > div {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 90%;
  font-variation-settings: 'wght' 600;
  backdrop-filter: blur(var(--base-unit));
  -webkit-backdrop-filter: blur(var(--base-unit));
  background-color: var(--white50);
  border-radius: var(--base-unit);
  padding: calc(var(--base-unit)*3) calc(var(--base-unit)*5);
  border:1px solid var(--black10);

  transform: translate(0,110%);
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
}
::-ms-input-placeholder {
  color: var(--light-gray);
  font-variation-settings: 'wght' 300;
}

::-webkit-input-placeholder {
  color: var(--light-gray);
  font-variation-settings: 'wght' 300;
}

span.active {
  background-color: var(--active);
  color: var(--darker-gray);
  border-radius: var(--base-unit);
  padding: var(--base-unit) calc(var(--base-unit)*2);
  font-variation-settings: 'wght' 600;
}
span.inactive {
  background-color: var(--inactive);
  color: var(--darker-gray);
  border-radius: var(--base-unit);
  padding: var(--base-unit) calc(var(--base-unit)*2);
  font-variation-settings: 'wght' 600;
}
span.matriz_filial {
  background-color: var(--primary);
  color: white;
  border-radius: var(--base-unit);
  padding: var(--base-unit) calc(var(--base-unit)*2);
  font-variation-settings: 'wght' 600;
  text-transform: uppercase;
  font-size: 80%;
}
span.certified {
  background-color: var(--certified);
  font-variation-settings: 'wght' 600;
  border-radius: calc(var(--base-unit)*3);
  font-size: 70%;
  display: flex;
  column-gap: var(--base-unit);
  align-items: center;
  padding: var(--base-unit) calc(var(--base-unit)*3);
  border:1px solid var(--black10);
  color: var(--darker-gray);
  position: relative;
}
span.certified div {
  visibility: hidden;
  position: absolute;
  z-index: 888;
  line-height: 1.25;
  top: calc(var(--base-unit)*6);
  left: calc(var(--base-unit)*5);
  padding: calc(var(--base-unit)*3);
  background-color: var(--certified);
  border:1px solid var(--black10);
  color: var(--darker-gray);
  border-radius: var(--base-unit);
}
span.certified:hover div {
  visibility: visible;
}
aside span.certified {
  display: inline-flex;
  margin-bottom: calc(var(--base-unit)*3);
}

nav#breadcrumbs ul {
  display: flex;
  /* justify-content: center; */
  font-size: 90%;
  margin-bottom: calc(var(--base-unit)*8);
}
nav#breadcrumbs ul li:not(:last-child)::after {
  content: "/";
  margin: 0 calc(var(--base-unit)*5) ;
}
h1, .home #intro .title {
  color: black;
  font-size: 250%;
  font-variation-settings: 'wght' 700;
  margin-bottom: calc(var(--base-unit)*3);
}
.home #intro form {
  display: inline-block;
}
#intro h2 {
  color: var(--gray);
  font-size: 150%;
  font-variation-settings: 'wght' 700;
}
.listing #intro h2 {
  font-variation-settings: 'wght' 400;
  letter-spacing: -1px;
  line-height: 1.25;
}
.listing #intro:has(p) h2 {
  margin-bottom: calc(var(--base-unit)*4);
}
main {
  margin-top: calc(var(--base-unit)*8);
}
main h3, .list h3, aside h3, .h3, main > h2 {
  color: var(--primary);
  font-variation-settings: 'wght' 700;
  font-size: 110%;
}
main > h2 {
  margin-bottom: calc(var(--base-unit)*4);
}
main h4, .h4, .list h4, aside h4 {
  color: var(--darker-gray);
  font-variation-settings: 'wght' 700;
}
main figure {
  width: 100%;
  aspect-ratio: 16/9;
  margin-bottom: calc(var(--base-unit)*8);
}
main figure .media {
  position: relative;

}
main figure .gradient {
  position:absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.5;
  z-index: 0;
  border-radius: var(--base-unit);
  mix-blend-mode: multiply;
  background: linear-gradient(180deg, rgba(181,25,137,1) 0%, rgba(181,25,137,0) 80%);
}
main figure img {
  width: 100%;
  border-radius: var(--base-unit);
  height: auto;
  object-fit: cover;
  aspect-ratio: 120/63;
}
main figure figcaption {
  font-size: 75%;
  color: var(--gray);
  margin-top: calc(var(--base-unit)*2);
  font-style: italic;
  text-align: center;
}
main figure span {
  font-size: 70%;
  padding: calc(var(--base-unit)*2);
  z-index: 1;
  position: absolute;
  bottom: calc(var(--base-unit)*4);
  right: calc(var(--base-unit)*4);
  color: white;
  border-radius: var(--base-unit);
  background-color: var(--black10);
  border: 1px solid var(--black10);
  backdrop-filter: blur(var(--base-unit));
  -webkit-backdrop-filter: blur(var(--base-unit));
}
main figure span a {
  color: white;
}
main figure span:hover {
  background-color: black;
}
main figure span::selection, main figure span a::selection {
  background-color: white;
  color: black;
}
.single main > p {
  font-size: 65%;
  line-height: 1.5;
  border-top: 1px solid var(--black10);
  padding-top: calc(var(--base-unit)*4);
  color: var(--gray);
}
.single article {
  margin-bottom: calc(var(--base-unit)*8);
}
.single #intro div {
  display: flex;
  column-gap: calc(var(--base-unit)*2);
  align-items: center;
}
span.currentPage {
  display: block;
  margin-top: var(--base-unit);
  font-style: italic;
  font-size: 90%;
  color: var(--gray);
}
.list h2, .content h2 {
  color: var(--darker-gray);
  font-variation-settings: 'wght' 700;
  margin-bottom: calc(var(--base-unit)*4);
  font-size: 110%;
}
.list p, .content p, .single article p {
  margin-bottom: calc(var(--base-unit)*4);
  line-height: 1.5;
}
.list .box {
  margin-bottom: calc(var(--base-unit)*5);
}
.list .box .columns-3 {
  grid-template-columns: 2fr 1fr 1fr;
}
.list .box .title {
  justify-content: space-between;
  align-items: center;
}
.resumido.box .title {
  flex-direction: column;
  align-items: flex-start;
}
.resumido .columns-2 {
  grid-template-columns: 1fr;
}
.resumido .title .d-flex {
  align-items: center;
  grid-column-gap: calc(var(--base-unit)*2);
  column-gap: calc(var(--base-unit)*2);
}
.list .box .title .d-flex {
  align-items: center;
  column-gap: calc(var(--base-unit)*2);
}
.list .box p, .resumido.box p {
  margin: 0;
}
.list .pagination .pages {
  background-color: var(--primary-light);
  border-radius: var(--base-unit);
  color: var(--gray);
  padding: calc(var(--base-unit)*2);
  margin-bottom: calc(var(--base-unit)*3);
}
.list .pagination .pages ul {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: calc(var(--base-unit)*2);
}
.list .pagination .pages ul li {
  padding: calc(var(--base-unit)*2);
}
.list .pagination .pages a {
  color: var(--primary);
  display: block;
}
.list .pagination .label {
  text-align: center;
  font-size: 90%;
}
.box .title {
  border-bottom: 1px solid var(--black10);
  display: flex;
  align-items: center;
  column-gap: calc(var(--base-unit)*3);
  padding-bottom: calc(var(--base-unit)*3);
  margin-bottom: calc(var(--base-unit)*5);
}
.box .title:has(button) {
  justify-content: space-between;
}
.box .title:has(.react-loading-skeleton) {
  display: block;
}
button[data-action=copyPhoneNumber] {
  margin-left: var(--base-unit);
}
.box {
  border-radius: var(--base-unit);
  border: 1px solid var(--black10);
  background: #FFF;
  box-shadow: 0px var(--base-unit) calc(var(--base-unit)) 0px var(--black10);
  padding: calc(var(--base-unit)*5);
  margin-bottom: calc(var(--base-unit)*8);
}
.box h4 {
  margin-bottom: calc(var(--base-unit)*2);
}
.box p {
  margin-bottom: calc(var(--base-unit)*5);
  line-height: 1.5;
}
.box:has(a[data-link="cnae-cidade"]) li {
  display: inline-block;
  margin: 0 calc(var(--base-unit)*2) calc(var(--base-unit)*2) 0;
}
.box:has(a[data-link="cnae-cidade"]) li a {
  display: block;
  text-decoration: none;
  background-color: var(--primary-light);
  border:1px solid var(--primary-light);
  border-radius: var(--base-unit);
  padding: var(--base-unit);
  font-size: 95%;
}
.box:has(a[data-link="cnae-cidade"]) li a:hover, .box:has(a[data-link="cnae-cidade"]) li.active a {
  background-color: var(--primary);
  color: white;
  opacity: 1 !important;
}
.box:has(a[data-link="cnae-cidade"]) input, .box:has(a[data-link="cnae-cidade"]) p {
  margin-top: calc(var(--base-unit)*2);
}
.box:has(a[data-link="cnae-cidade"]) h4:not(:first-of-type) {
  margin-top: calc(var(--base-unit)*3);
}
.d-flex {
  display: flex;
  justify-content: space-between;
}
.socio main > .d-flex {
  margin-bottom: calc(var(--base-unit)*4);
}
.box button, .socio .d-flex button, .reports main button {
  border: 2px solid var(--black10);
  background-color: white;
  border-radius: var(--base-unit);
  padding: calc(var(--base-unit)*2) calc(var(--base-unit)*3);
  cursor: pointer;
  font-family: 'Inter',sans-serif;
  font-variation-settings: 'wght' 600;
  color: black;
}
.reports main button {
  margin-right: var(--base-unit);
}
.box button:hover, .socio .d-flex button:hover, .reports main button:hover {
  background-color: var(--primary-light);
  border-color: var(--primary);
}
.box#estabelecimentos button {
  margin: calc(var(--base-unit)*3) auto 0;
  display: block;
}
.box button.primary, .reports main button.primary {
  background-color: var(--primary);
  color: white;
}
.box button.primary:hover, .reports main button.primary:hover {
  opacity: 0.75;
}
.reports .box {
  height: fit-content;
}
.reports form label {
  display: block;
  margin-bottom: calc(var(--base-unit)*2);
}
.reports form label:not(:first-child) {
  margin-top: calc(var(--base-unit)*4);
}
.reports form input {
  border: 2px solid var(--black10);
  padding: calc(var(--base-unit)*3);
  border-radius: var(--base-unit);
  color: black;
  font-family: 'Inter',sans-serif;
  font-variation-settings: 'wght' 700;
  width: -webkit-fill-available;
}
.reports form input:focus {
  outline: 0;
  border-color: var(--gray);
  background-color: #f7f7f7;
}
.reports form button {
  display: block;
  margin-top: calc(var(--base-unit)*4);
}
.box .map {
  background:url(./assets/map.webp) no-repeat;
  background-size: cover;
  aspect-ratio: 4/3;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: calc(var(--base-unit)*5);
  margin-bottom: calc(var(--base-unit)*5);
  border: 1px solid var(--black10);
}
table {
  font-size: 80%;
  width: 100%;
  line-height: 1.25;
}
table tr {
  border-bottom: 1px solid var(--black10);
}
table td, table th {
  padding: calc(var(--base-unit)*3);
}
table th {
  text-align: left;
}
table thead {
  background-color: var(--primary);
  font-variation-settings: 'wght' 600;
  color: white;
  position: sticky;
  top: 0;
}
table td:nth-child(1) {
  width: calc(var(--base-unit)*75);
}
table td:nth-child(2) {
  width: calc(var(--base-unit)*50);
}
#cnaes table td:nth-child(1) {
  width: calc(var(--base-unit)*25);
}
#cnaes table td:nth-child(2) {
  width: auto
}
table tbody tr {
  background-color: white;
}
table tbody tr:nth-child(2n-1) {
  background-color: var(--lightest-gray);
}

.cookieAlert {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 5;
  width: 100vw;
  background-color: var(--white80);
  backdrop-filter: blur(calc(var(--base-unit)*2));
  -webkit-backdrop-filter: blur(calc(var(--base-unit)*2));
  padding: calc(var(--base-unit)*2);
  text-align: center;
}
.cookieAlert p {
  text-align: left;
  display: inline;
  font-size: 90%;
  margin-bottom: 0;
}
.cookieAlert p a {
  text-decoration: underline;
}
.cookieAlert button {
  width: fit-content;
  margin-left: var(--base-unit);
  font-size: 70%;
  text-transform: uppercase;
  border: 2px solid var(--black10);
  background-color: var(--primary);
  border-radius: var(--base-unit);
  padding: calc(var(--base-unit)*2) calc(var(--base-unit)*3);
  cursor: pointer;
  font-family: 'Inter',sans-serif;
  font-variation-settings: 'wght' 600;
  color: white;
}
.home #intro {
  background-color: var(--primary);
  color: white;
  text-align: center;
  margin-bottom: calc(var(--base-unit)*8);
  padding: calc(var(--base-unit)*15) 0;
}
.home #intro h1 {
  display: inline;
  font-size: 100%;
}
.home #intro .title, .home #intro h1 {
  color: white;
}
.home #intro h2 {
  color: var(--white80);
  margin-bottom: calc(var(--base-unit)*4);
}
.home #intro input {
  width: calc(var(--base-unit)*150);
  padding: calc(var(--base-unit)*4);
  padding-right: calc(var(--base-unit)*10);
  font-size: 110%;
}
.home .content {
  margin-top: calc(var(--base-unit)*8);
}
.home header {
  margin-bottom: 0;
}
main article ul, main article ol {
  margin-left: calc(var(--base-unit)*4);
  margin-bottom: calc(var(--base-unit)*3);
}
main article ol {
  counter-reset: custom-counter;
}
main article ul li, main article ol li {
  color: var(--gray);
  line-height: 1.5;
  margin-bottom: calc(var(--base-unit)*3);
}
main article ol li::before {
  content: counter(custom-counter); /* Usa o valor do contador personalizado como conteúdo */
  counter-increment: custom-counter; /* Incrementa o contador personalizado */
  color: var(--primary);
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
main article ul li::before {
  content: "\2022";
  color: var(--primary);
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
footer {
  background-color: white;
  border-top: 1px solid var(--black10);
  margin-top: calc(var(--base-unit)*15);
  padding-top: calc(var(--base-unit)*15);
  display: flex;
  row-gap: calc(var(--base-unit)*15);
  flex-direction: column;
  justify-content: space-evenly;
}
footer a {
  text-decoration: none;
}
footer .d-flex {
  margin-bottom: calc(var(--base-unit)*5);
}
footer .h2 {
  color: var(--gray);
  font-variation-settings: 'wght' 700;
  font-size: 110%;
}
footer .d-flex p:not(.h2) {
  font-size: 90%;
}
footer .d-flex p:not(.h2) a {
  text-decoration: underline;
}
footer .blog {
  display: none;
  border-bottom: 1px solid var(--primary);
  padding-bottom: calc(var(--base-unit)*15);
}
footer .post {
  border: 1px solid var(--black10);
  border-radius: var(--base-unit);
}
footer figure {
  position: relative;
  aspect-ratio: 33/15;
  /* aspect-ratio: 16/9; */
}
footer figure .category {
  border-radius: var(--base-unit);
  color: var(--darker-gray);
  background-color: var(--white50);
  backdrop-filter: blur(var(--base-unit));
  -webkit-backdrop-filter: blur(var(--base-unit));
  font-size: 80%;
  text-transform: uppercase;
  position: absolute;
  font-variation-settings: 'wght' 700;
  z-index: 1;
  top: calc(var(--base-unit)*5);
  left: calc(var(--base-unit)*5);
  padding: var(--base-unit) calc(var(--base-unit)*2);
}
footer figure .category:hover {
  /* background-color: var(--primary);
  color: white; */
  background-color: white;
  color: var(--primary);
}
footer figure .title {
  color: var(--primary);
  font-size: 120%;
  position: absolute;
  font-variation-settings: 'wght' 700;
  z-index: 1;
  bottom: 0;
  left: calc(var(--base-unit)*5);
}
footer figure .gradient {
  position:absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 80%);
}
footer figure img {
  width: 100%;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  height: 100%;
  object-fit: cover;
}
footer .post p {
  padding: calc(var(--base-unit)*5);
  line-height: 1.5;
  font-size: 75%;
}
footer .post:hover {
  background-color: var(--primary);
}
footer .post:hover .gradient{
  background: linear-gradient(180deg, rgba(181,25,137,0) 0%, rgba(181,25,137,1) 80%);
}
footer .post:hover .title {
  color: white;
  transform: translateY(-20%);
}
footer .post:hover p {
  color: var(--white50);
}

footer .columns-footer a {
  color: var(--gray);
}
footer .columns-footer a:hover {
  color: var(--primary);
  text-decoration: underline;
}
footer .columns-footer ul:last-child a {
  text-decoration: underline;
  color: var(--gray);
}
footer .columns-footer ul:last-child a:hover, footer .d-flex p:not(.h2) a:hover, #breadcrumbs a:hover {
  opacity: 0.75;
  color: var(--primary);
  text-decoration: none;
}
footer > div:last-child {
  background-color: var(--primary);
  border-top-right-radius: var(--base-unit);
  border-top-left-radius: var(--base-unit);
  padding: calc(var(--base-unit)*5);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: -webkit-fill-available;
}
footer > div:last-child a {
  color: white;
}
footer > div:last-child ul {
  display: flex;
  column-gap: calc(var(--base-unit)*8);
}
footer > div:last-child ul a {
  font-size: 80%;
}
footer > div:last-child ul:first-child li:first-child a {
  font-size: 120%;
  font-variation-settings: 'wght' 600;
}
footer > div:last-child a:hover {
  opacity: 0.75;
}
/* discard */
.ads {
  /* border: 1px dashed var(--light-gray); */
  /* border-radius: var(--base-unit); */
  color: var(--black10);
  display: flex;
  max-width: 960px;
  justify-content: center;
  align-items: center;
  /* padding: calc(var(--base-unit)*10); */
  margin-bottom: calc(var(--base-unit)*8);
  text-transform: uppercase;
  display: none;
}
.ads img {
  max-width: 100%;
  height: auto;
  width: 540px;
  aspect-ratio: 1;
}
aside .ads {
  display: flex;
  aspect-ratio: 1;
}
aside:has(.ads), aside:has(.box) {
  padding-top: calc(var(--base-unit)*8);
}
aside input {
  width: -webkit-fill-available;
}
div:not(#root):has(#breadcrumbs) .ads {
  position: sticky;
  top: calc(var(--base-unit)*8);
  background-color: var(--white50);
  backdrop-filter: blur(calc(var(--base-unit)*2));
  -webkit-backdrop-filter: blur(calc(var(--base-unit)*2));
}

.d-none {
  display: none !important;
}

@media screen and (max-width: 1024px) {
  .bordered {
      padding: 0 calc(var(--base-unit)*4);
  }
  nav#breadcrumbs ul li:not(:last-child)::after {
    margin: 0 calc(var(--base-unit)*3) ;
  }
  .home #intro {
    padding: calc(var(--base-unit)*10) calc(var(--base-unit)*5);
  }
  .home #intro input {
      width: 90vw;
  }
  .home #intro .title {
      font-size: 220%;
  }
  .box .title {
    flex-direction: column;
    text-align: center;
    row-gap: calc(var(--base-unit)*2);
  }
  .columns-1fr_300px {
    display: block;
  }
  .columns-330px_1fr {
    display: flex;
    flex-direction: column-reverse;
  }
  .columns-2 {
    display: block;
  }
  .columns-3 {
    display: block;
  } 
  .columns-footer {
    display: flex;
    flex-direction: column;
  }
  .single #intro h1, .socio #intro h1, .socio #intro p, .socio .list h2 {
    text-align: center;
  }
  .socio .d-flex {
    flex-direction: column;
  }
  .single #intro div {
    flex-direction: column;
    row-gap: calc(var(--base-unit)*2);
  }
  footer {
    row-gap: calc(var(--base-unit)*10);
    padding-top: calc(var(--base-unit)*10);
  }
  footer > div:last-child {
    border-radius: 0 !important;
    flex-direction: column;
    justify-content: center;
    row-gap: calc(var(--base-unit)*5);
  }
  footer.iphone > div:last-child {
    padding-bottom: calc(var(--base-unit)*15);
  }
  .cookieAlert.iphone {
    padding-bottom: calc(var(--base-unit)*8);
  }
  .cookieAlert button {
    margin-left: 0;
    margin-top: calc(var(--base-unit)*3);
  }
  footer > div:last-child ul {
    text-align: center;
    flex-direction: column;
    row-gap: calc(var(--base-unit)*4);
  }
  body.paddingBottom footer > div:last-child {
    border-radius: 0 !important;
  }
  h1 {
  font-size: 220%;
  }
  .box {
    overflow:scroll;
  }
  header {
    z-index: 997;
    position: sticky;
    top: 0;
  }
  header .top button {
    background-color: transparent;
    border: 0;
    color: var(--primary);
    fill: var(--primary);
    font-variation-settings: 'wght' 600;
  }
  header .top button svg {
    margin-left: var(--base-unit);
  }
  header .top input, header .top ul {
    display: none;
  }
  header .top button {
    display: flex;
  }
  header .bottom {
    display: none;
  }
  header .bottom, header ul {
    flex-direction: column;
  }
  header .bottom button {
    display: flex;
    font-size: 100%;
  }
  .home .columns-2 .d-flex:has(h2) {
    flex-direction: column;
  }
  .home .columns-2 .list {
    margin-bottom: calc(var(--base-unit)*8);
  }
  .home header {
    background-color: var(--primary);
  }
  .home header a {
    color: white
  }
  .home header button {
    color: white
  }
  .home header .brand_and_search a svg .card {
    color: white;
    fill: white;
  }
  .home header .brand_and_search a .cnpj, .home header .brand_and_search a .lines {
    color: var(--primary);
    fill: var(--primary);
  }
  .list .box {
    margin-bottom: calc(var(--base-unit)*4);
  }
  .single main > p {
    font-size: 85%;
  }
  .box .columns-2 > div:first-child {
    margin-bottom: calc(var(--base-unit)*3);
  }
  .modal .content {
    width: 80vw;
  }
  .modal button {
    z-index: 999;
  }
  .ads {
    display: flex;
  }
  div:not(#root):has(#breadcrumbs) .ads {
    position:static;
  }
}


